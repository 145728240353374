import { registerApplication, start } from "single-spa";
import { constructApplications, constructRoutes, constructLayoutEngine } from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import PubSub from "pubsub-js";


let publish = (event, data) => PubSub.publish(event, data);
const subscribe = (event, callback) => PubSub.subscribe(event, callback);


const customLoader = `
<style>
.container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.loader {
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}

@keyframes mulShdSpin {

  0%,
  100% {
	box-shadow: 0em -2.6em 0em 0em #004a9f, 1.8em -1.8em 0 0em rgba(0, 74, 159, 0.2), 2.5em 0em 0 0em rgba(0, 74, 159, 0.2), 1.75em 1.75em 0 0em rgba(0, 74, 159, 0.2), 0em 2.5em 0 0em rgba(0, 74, 159, 0.2), -1.8em 1.8em 0 0em rgba(0, 74, 159, 0.2), -2.6em 0em 0 0em rgba(0, 74, 159, 0.5), -1.8em -1.8em 0 0em rgba(0, 74, 159, 0.7);
  }

  12.5% {
	box-shadow: 0em -2.6em 0em 0em rgba(0, 74, 159, 0.7), 1.8em -1.8em 0 0em #004a9f, 2.5em 0em 0 0em rgba(0, 74, 159, 0.2), 1.75em 1.75em 0 0em rgba(0, 74, 159, 0.2), 0em 2.5em 0 0em rgba(0, 74, 159, 0.2), -1.8em 1.8em 0 0em rgba(0, 74, 159, 0.2), -2.6em 0em 0 0em rgba(0, 74, 159, 0.2), -1.8em -1.8em 0 0em rgba(0, 74, 159, 0.5);
  }

  25% {
	box-shadow: 0em -2.6em 0em 0em rgba(0, 74, 159, 0.5), 1.8em -1.8em 0 0em rgba(0, 74, 159, 0.7), 2.5em 0em 0 0em #004a9f, 1.75em 1.75em 0 0em rgba(0, 74, 159, 0.2), 0em 2.5em 0 0em rgba(0, 74, 159, 0.2), -1.8em 1.8em 0 0em rgba(0, 74, 159, 0.2), -2.6em 0em 0 0em rgba(0, 74, 159, 0.2), -1.8em -1.8em 0 0em rgba(0, 74, 159, 0.2);
  }

  37.5% {
	box-shadow: 0em -2.6em 0em 0em rgba(0, 74, 159, 0.2), 1.8em -1.8em 0 0em rgba(0, 74, 159, 0.5), 2.5em 0em 0 0em rgba(0, 74, 159, 0.7), 1.75em 1.75em 0 0em #004a9f, 0em 2.5em 0 0em rgba(0, 74, 159, 0.2), -1.8em 1.8em 0 0em rgba(0, 74, 159, 0.2), -2.6em 0em 0 0em rgba(0, 74, 159, 0.2), -1.8em -1.8em 0 0em rgba(0, 74, 159, 0.2);
  }

  50% {
	box-shadow: 0em -2.6em 0em 0em rgba(0, 74, 159, 0.2), 1.8em -1.8em 0 0em rgba(0, 74, 159, 0.2), 2.5em 0em 0 0em rgba(0, 74, 159, 0.5), 1.75em 1.75em 0 0em rgba(0, 74, 159, 0.7), 0em 2.5em 0 0em #004a9f, -1.8em 1.8em 0 0em rgba(0, 74, 159, 0.2), -2.6em 0em 0 0em rgba(0, 74, 159, 0.2), -1.8em -1.8em 0 0em rgba(0, 74, 159, 0.2);
  }

  62.5% {
	box-shadow: 0em -2.6em 0em 0em rgba(0, 74, 159, 0.2), 1.8em -1.8em 0 0em rgba(0, 74, 159, 0.2), 2.5em 0em 0 0em rgba(0, 74, 159, 0.2), 1.75em 1.75em 0 0em rgba(0, 74, 159, 0.5), 0em 2.5em 0 0em rgba(0, 74, 159, 0.7), -1.8em 1.8em 0 0em #004a9f, -2.6em 0em 0 0em rgba(0, 74, 159, 0.2), -1.8em -1.8em 0 0em rgba(0, 74, 159, 0.2);
  }

  75% {
	box-shadow: 0em -2.6em 0em 0em rgba(0, 74, 159, 0.2), 1.8em -1.8em 0 0em rgba(0, 74, 159, 0.2), 2.5em 0em 0 0em rgba(0, 74, 159, 0.2), 1.75em 1.75em 0 0em rgba(0, 74, 159, 0.2), 0em 2.5em 0 0em rgba(0, 74, 159, 0.5), -1.8em 1.8em 0 0em rgba(0, 74, 159, 0.7), -2.6em 0em 0 0em #004a9f, -1.8em -1.8em 0 0em rgba(0, 74, 159, 0.2);
  }

  87.5% {
	box-shadow: 0em -2.6em 0em 0em rgba(0, 74, 159, 0.2), 1.8em -1.8em 0 0em rgba(0, 74, 159, 0.2), 2.5em 0em 0 0em rgba(0, 74, 159, 0.2), 1.75em 1.75em 0 0em rgba(0, 74, 159, 0.2), 0em 2.5em 0 0em rgba(0, 74, 159, 0.2), -1.8em 1.8em 0 0em rgba(0, 74, 159, 0.5), -2.6em 0em 0 0em rgba(0, 74, 159, 0.7), -1.8em -1.8em 0 0em #004a9f;
  }
}
</style>
<div class="container">
<span class="loader"></span>
</div>
`;

const data = {
  loaders: {
    customLoader,
  },
  props: {
    launchPadOpen: true,
    publish,
    subscribe
  }
};
const routes = constructRoutes(microfrontendLayout, data);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();
